
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import PeopleFind from "../../../components/People/people-find.vue";
import Cliente from "../../../store/entities/OZONE/cliente";
import Marca from "../../../store/entities/OZONE/marca";
import History from "../audit/history.vue";
import Campana from "../../../store/entities/OZONE/campana";
import Exportacion from "../../../store/entities/OZONE/exportacion";
import FinderResult from "../../../store/entities/OZONE/finderResult";
import SelectCampana from "../campana/selectCampana.vue";

class PageBuscadorRequest extends PageRequest {
  keyword: string;
  fechaInicio: Date;
  fechaFin: Date;
  campana: Campana;
  exportacion: Exportacion;
  enviada: Boolean;
  emitida: Boolean;
  pendiente: Boolean;
  rechazada: Boolean;
  ids: Array<number>;
  types: Array<number>;
  min: number;
  max: number;
  filter: string;
  email: string;
  dNI: string;
}

@Component({
  components: {
    PeopleFind,
    History,
    SelectCampana,
  },
})
export default class Campanas extends AbpBase {
  //filters
  pagerequest: PageBuscadorRequest = new PageBuscadorRequest();
  creationTime: Date[] = [];
  authorsHeaderBtns: String = "all";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  findModalShow: boolean = false;
  clienteNombre: string = "";
  query: any = null;
  historyModalShow: boolean = false;
  entityId: number = 0;
  currentSearch: { key: string; value: string; label: string }[] = [];
  withinCampaign: Campana = null;
  checkSearchByCampaign: boolean = false;
  cliente: Cliente = null;
  marca: Marca = null;
  search: string = null;
  pagination: any = {};

  plainOptions = ["Participaciones", "Transferencias", "Envios/Devoluciones"];
  defaultCheckedList = [
    "Participaciones",
    "Transferencias",
    "Envios/Devoluciones",
  ];

  checkedList: string[] = this.defaultCheckedList;
  indeterminate: boolean = false;
  checkAll: boolean = true;
  $store: any;

  onChange(checkedList) {
    this.indeterminate =
      !!checkedList.length && checkedList.length < this.plainOptions.length;
    this.checkAll = checkedList.length === this.plainOptions.length;
  }
  onCheckAllChange(e) {
    Object.assign(this, {
      checkedList: e.target.checked ? this.plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }

  onCheckCampaing(e) {
    this.checkSearchByCampaign = e.target.checked;
    if (!e.target.checked) {
      this.withinCampaign = null;
    }
  }

  get loading() {
    return this.$store.state.finder.loading;
  }

  pageChange(page: number) {
    this.$store.commit("campana/setCurrentPage", page);
    this.getpage();
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit("campana/setPageSize", pagesize);
    this.getpage();
  }

  async onSearch(value) {
    value = value ? value : this.query;

    if (!value || (value.length < 3 && isNaN(value))) {
      this.$message.error("Su búsqueda debe tener al menos 3 caracteres!");
      return;
    }

    // if (this.checkSearchByCampaign && this.withinCampaign == null) {
    //   this.$message.error("¡Debe seleccionar una campaña para la búsqueda!");
    //   return;
    // }

    this.pagerequest.keyword = null;
    this.pagerequest.email = null;
    this.pagerequest.filter = null;
    var types = [];

    if (this.checkedList.includes("Participaciones")) {
      types.push(1);
    }
    if (this.checkedList.includes("Transferencias")) {
      types.push(2);
    }
    if (this.checkedList.includes("Envios/Devoluciones")) {
      types.push(3);
    }

    if (types.length == 0) {
      this.$message.error("Debe seleccionar al menos un tipo de búsqueda!");
      return;
    }

    this.pagerequest.types = types;
    this.pagerequest.keyword = value;
    this.pagerequest.campana = this.withinCampaign;
    this.pagerequest.maxResultCount = this.pageSize;

    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: "finder/getAll",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.finder.totalCount;
    pagination.pageSize = this.$store.state.finder.pageSize;
    pagination.current = this.$store.state.finder.currentPage;
    pagination.showSizeChanger = true;
    pagination.pageSizeOptions = ["10", "50", "100"];
    this.pagination = pagination;
  }

  async getpage() {}

  get list() {
    return this.$store.state.finder.list;
  }

  get pageSize() {
    return this.$store.state.finder.pageSize;
  }

  get totalCount() {
    return this.$store.state.finder.totalCount;
  }

  get currentPage() {
    return this.$store.state.finder.currentPage;
  }

  set currentPage(page) {
    this.$store.commit("finder/setCurrentPage", page);
    this.getpage();
  }

  stateColorScheme(state: number) {
    switch (state) {
      case 1:
        return "ant-tag-primary";
      case 3:
        return "ant-tag-secondary";
      case 4:
        return "ant-tag-success";
      case 5:
        return "ant-tag-danger";
      case 6:
        return "ant-tag-warning";
    }
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("finder/setCurrentPage", pagination.current);
    this.$store.commit("finder/setPageSize", pagination.pageSize);
    await this.onSearch(null);
  };

  columns = [
    {
      title: this.L("Tipo"),
      dataIndex: "type",
      scopedSlots: { customRender: "type" },
    },
    // {
    //   title: this.L("ID"),
    //   dataIndex: "id",
    // },
    {
      title: this.L("Nombre"),
      scopedSlots: { customRender: "name" },
    },
    {
      title: this.L("Estado"),
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.L("Regalo"),
      dataIndex: "aux2",
    },
    {
      title: this.L("Campaña"),
      scopedSlots: { customRender: "campaign" },
    },
    {
      title: this.L("Fecha Creacion"),
      dataIndex: "fecha",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Participacion"),
      scopedSlots: { customRender: "link" },
    },
  ];

  async created() {}

  getEstadoColor(estado) {
    switch (estado) {
      case "Válido":
        return "ant-tag-success";
      case "Gestión Contact Center":
        return "ant-tag-secondary";
      case "Inválido":
        return "ant-tag-danger";
      case "Pendiente":
        return "ant-tag-warning";
      default:
        return "ant-tag-primary";
    }
  }

  getSubEstadoColor(estado) {
    switch (estado) {
      case "Válido":
        return "green";
      case "Gestión Contact Center":
        return "blue";
      case "Inválido":
        return "red";
      case "Pendiente":
        return "orange";
      default:
        return "black";
    }
  }

  goToParticipacion(item) {
    this.$store.commit("participacion/edit", { item });
    this.$router.push({ name: "editparticipacion", params: { id: item.id } });
  }
}
