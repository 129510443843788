
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import Modal from '../../../components/Modal.vue'

@Component({
  components: { Modal }
})
export default class DetailHistoryParticipacion extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: String }) id: string
  @Prop({ type: Number }) entityChangeSetId: number
  @Prop({ type: String }) title: string
  //filters
  pagination: any = {}
  viewModalShow = false

  get listPropertyChange() {
   
    return this.$store.state.audit.listPropertyChange.filter((x) => x.propertyName !== 'ConcurrencyStamp' && x.propertyName !== 'LastModificationTime' && x.propertyName !== 'CreationTime' && x.propertyName !== 'EstadoParticipacionTree' && x.propertyName !== 'GrupoTree')
  }
  get loading() {
    return this.$store.state.audit.loading
  }
  async create() {}

  async shown() {

    
    await this.$store.dispatch({
      type: 'audit/getDetail',
      data: { entityChangeId: this.entityChangeSetId }
    })
    
  }

  close() {
    this.$emit('input', false)
  }

  async created() {}
  columns = [
    {
      title: this.L('Property'),
      dataIndex: 'propertyName',
      scopedSlots: { customRender: 'func' }
    },
    {
      title: this.L('OriginalValue'),
      dataIndex: 'originalValue'
    },
    {
      title: this.L('NewValue'),
      dataIndex: 'newValue'
    }
  ]
}
